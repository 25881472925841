*{
  padding: 0;
  margin: 0;
}

button:hover{
  cursor: pointer;
  filter: brightness(150%);
}

.App{
  display: grid;
  background: skyblue;
}
h2{
  font-size: 40px;
}
h1{
  font-size: 50px;
}
h2,h1{
  font-family: "Bagel Fat One", system-ui;
  font-weight: 400;
  font-style: normal;
}
span,a,button{
  font-family: "Ubuntu", serif;
  font-weight: 300;
  font-style: normal;
}
a{
  font-weight: 500;
}
#Hero{
  background: skyblue;
  background-image: url("./images/normalHero.png");
  background-size: 100vw;
  background-repeat: no-repeat;
  height: 100vh;
}

a{
  text-decoration: none;
  color: black;
}

button{
  background: #5a002c;
  border: none;
  padding: 10px 30px;
  border-radius: 30px;
  font-weight: 500;
  color: white;
}

#Menu a:focus{
  background: orange;
  border-radius: 30px;
}

#MenuLeft a{
  padding: 10px;
}

#MenuLeft a:hover{
  background: #4a61d3;
  border-radius: 20px;
  color: white;
}

#Menu{
  background: white;
  display: flex;
  width: 40vw;
  justify-content: space-between;
  justify-self: center;
  align-items: center;
  padding: 10px 30px;
  margin: 30px;
  border: 1px solid black;
  border-radius: 30px;
}

#MenuLeft{
  display: flex;
  align-items: center;
}


#MenuRight{
  display: flex;
  gap: 10px;
  align-items: center;
}

#MenuRight img{
  width: 20px;
  height: 20px;
  align-self: center;
  justify-self: center;
}

#MenuRight a:hover{
  scale: 1.15;
  transition: 0.2s ease-in-out;
}

#MenuRight a{
  display: flex;
  align-content: center;
  justify-content: center;
  background: black;
  padding: 6px;
  border-radius: 100%;
}

#Addy{
  display: grid;
  justify-content: center;
  text-align: center;
  background: skyblue;
  gap: 50px;
  padding: 50px 0;
}

#AddyTop{
  display: grid;
  gap: 30px;
}

#AddyTopBottom{
  display: flex;
  justify-self: center;
  align-items: center;
  background: white;
  padding: 10px 20px;
  gap: 10px;
  border-radius: 10px;
}

#AddyBottom{
  display: flex;
}
#AddyLeft{
  display: grid;
  height: fit-content;
  justify-items: center;
}
#AddyContainer{
  display: grid;
  width: 75%;
  border-radius: 30px;
  border: 3px solid black;
  padding: 30px;
  rotate: -2deg;
  gap: 10px;
  filter: drop-shadow(0px 5px 5px #00000050);
  margin-top: 5px;
}

#Bumpbot{
  background: rgb(30,50,90);
  color: white;
  display: grid;
  text-align: center;
  gap: 50px;
  padding: 50px 20px;
}

#BumpbotContainer{
  display: flex;
  width: 90%;
  justify-self: center;
  justify-content: center;
  gap: 100px;
}

#BumpbotCard{
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
  max-width: 25%;
}

#Bumpbot img{
  width: 300px;
  border-radius: 30px;
  justify-self: center;
}

#Buy{
  display: grid;
  background: linear-gradient(skyblue,lightblue);
  justify-content: center;
  text-align: center;
  gap: 30px;
  padding-top: 100px;
}

#BuyContainer{
  display: flex;
  width: 80%;
  justify-self: center;
  gap: 30px;
}

#BuyCard h2{
  color: white;
  font-size: 35px;
}

#BuyCard span{
  font-weight: 500;
}

#BuyCard{
  background: rgb(120, 158, 152);
  display: grid;
  grid-template-rows: 1fr 2fr 1fr;
  padding: 10px;
  gap: 30px;
  border: 5px solid black;
  border-radius: 30px;
}

#BuyCard button, #About button{
  background: orangered;
  border: black 3px solid;
  font-weight: 700;
  color: white;
  width: 80%;
  justify-self: center;
  border-radius: 10px;
}

#About{
  display: grid;
  background: linear-gradient(lightblue,skyblue);
  text-align: center;
}

#AboutContainer{
  display: flex;
  align-items: center;
  justify-self: center;
}

#AboutCard{
  display: grid;
  width: 400px;
  height: fit-content;
  border: 2px solid black;
  padding: 20px;
  border-radius: 20px;
  rotate: -2deg;
}

#AboutCard h1{
  color: orangered;
}
#AboutCard h2{
  font-size: 20pt;
}
#AboutCard button{
  font-size: 20pt;
  margin-top: 20px;
}

#Footer{
  display: flex;
  background: linear-gradient(skyblue,lightblue);
  justify-self: center;
  justify-content: center;
  width: 100%;
  padding: 30px 0;
  gap: 10px;
}

#Generator{
  display: grid;
  background: lightblue;
  justify-content: center;
  text-align: center;
  padding: 50px;
  gap: 20px;
}

#GeneratorCanvas{
  width: 30vw;
  justify-self: center;
}

#GeneratorSection{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  gap: 10px;
}

#Generator input{
  padding: 10px;
  border: 3px solid black;
  border-radius: 10px;
  word-wrap: break-word;
  word-break: break-word;
}

#Generator button{
  padding: 20px;
  font-size: 20pt;
}

#BuyCard button, #Generator button, #About button, #Addy button, #Menu button, #Bumpbot button{
  display: flex;
  align-items: center;
  justify-content: center;
  justify-self: center;
  line-height: 1;
  text-decoration: none;
  color: white;
  font-size: 18px;
  border-radius: 20px;
  width: 90%;
  height: 60px;
  font-weight: bold;
  border: 2px solid black;
  transition: 0.3s;
  box-shadow: 0px 6px 0px -2px darkred;
  background-color: orangered;
  filter: drop-shadow(0px 5px 3px #00000040);
}

#Bumpbot button{
  width: fit-content;
}

#Menu button{
  height: fit-content;
  width: fit-content;
  padding: 10px 20px;
  background-color: #72aa4c;
  box-shadow: 0px 6px 0px -2px rgb(44, 0, 44);
}

#BuyCard button:hover,#Generator button:hover, #About button:hover, #Addy button:hover, #Menu button:hover, #Bumpbot button:hover{
  box-shadow: 0 0 #fff;
  transform: translateY(1px);
  filter: none;
}

@media only screen and (max-width:1366px){
  .App{
    background-color: #bdd8eb;
  }
  #Menu{
    width: 60%;
    margin-top: 0px;
  }
  #Hero{
    background-size: 100vw 50vw;
    height: 50vw;
    width: 100vw;
    margin-top: 20px;
  }
  #BuyContainer{
    width: 90%;
  }
  #BuyCard{
    width: 100%;
  }
  #BuyCard h2{
    font-size: 30px;
  }
  #Bumpbot{
    background: rgb(30,50,90);
    color: white;
    display: grid;
    text-align: center;
    gap: 50px;
    padding: 50px 20px;
  }
  
  #BumpbotContainer{
    display: flex;
    width: 90%;
    justify-self: center;
    justify-content: center;
    gap: 100px;
  }
  #BumpbotCard h2{
    font-size: 30px;
  }
  #BumpbotCard{
    display: grid;
    grid-template-rows: 0fr 1fr;
    gap: 20px;
    max-width: 25%;
  }
  #Bumpbot img{
    width: 300px;
    border-radius: 30px;
    justify-self: center;
  }
}

@media only screen and (max-width: 480px){
  .App{
    background-color: #bdd8eb;
  }
  #Menu{
    display: grid;
    width: 90%;
    height: fit-content;
    padding: 10px 0;
    justify-content: center;
    margin: -40px 0 0 0;
  }
  #MenuLeft, #MenuRight{
    justify-self: center;
    align-items: center;
  }
  #MenuLeft{
    visibility: hidden;
    display: none;
  }
  #Hero{
    display: grid;
    background-size: 100vw 50vw;
    height: 50vw;
    width: 100vw;
    margin-top: 50px;
  }
  #Menu button{
    font-size: 15px;
  }

  #AddyBottom{
    display: grid;
  }
  #BuyContainer{
    display: grid;
    width: 100%;
    justify-items: center;
  }
  #BuyCard{
    width: 80%;
    grid-template-rows: none;
    padding: 20px;
  }
  #Addy{
    display: grid;
    gap: 30px;
    padding: 50px 0 0 0;
  }
  #Addy img, #About img{
    width: 300px;
    justify-self: center;
  }
  #AboutContainer{
    display: grid;
  }
 
  #AddyTopBottom span{
    text-overflow: ellipsis;
    overflow: hidden;
    width: 160px;
    height: 1.2em;
    white-space: nowrap;
  }

  #AddyBottom{
    margin-top: 50px;
  }
  #AddyContainer{
    width: 70%;
    padding: 20px;
  }
  #AddyContainer h2{
    font-size: 20px;
  }
  #AddyBottom img{
    width: 300px;
  }
  #Buy{
    padding: 0;
  }
  #About{
    gap: 30px;
  }
  #AboutCard{
    width: 70%;
    justify-self: center;
  }
  #Addy{
    gap: 0;
  }
  #Generator{
    padding: 0;
    padding-top: 30px;
    justify-self: center;
    width: 100%;
  }
  #Generator h1{
    font-size: 30pt;
  }
  #GeneratorSection{
    grid-template-columns: 1fr 1fr;
  }
  #GeneratorCanvas{
    width: 90vw;
  }
  #GeneratorBundle input{
    padding: 10px;
  }

  #Generator input{
    width: 30vw;
  }

  #Bumpbot{
    background: rgb(30,50,90);
    color: white;
    display: grid;
    text-align: center;
    gap: 50px;
    padding: 50px 20px;
  }
  
  #BumpbotContainer{
    display: grid;
    width: 90%;
    justify-self: center;
    justify-content: center;
    gap: 50px;
  }
  
  #BumpbotCard{
    display: grid;
    grid-template-rows: 1fr 1fr;
    gap: 20px;
    max-width: 100%;
  }
  
  #Bumpbot img{
    width: 300px;
    border-radius: 30px;
    justify-self: center;
  }
}